<template>
    <nav aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item">
          <a
            class="page-link"
            :class="{ 'disabled': props.currentPage === 1 }"
            href="#"
            aria-label="Previous"
            @click.prevent="goToPage(props.currentPage - 1)"
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
  
        <li class="page-item" :class="{ 'active': props.currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="goToPage(1)">1</a>
        </li>
  
        <li v-if="showLeftEllipsis" class="page-item disabled">
          <span class="page-link">...</span>
        </li>
  
        <li v-for="page in middlePages" :key="page" class="page-item" :class="{ 'active': page === props.currentPage }">
          <a class="page-link" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
        </li>
  
        <li v-if="showRightEllipsis" class="page-item disabled">
          <span class="page-link">...</span>
        </li>
  
        <li v-if="props.totalPages > 1" class="page-item" :class="{ 'active': props.currentPage === props.totalPages }">
          <a class="page-link" href="#" @click.prevent="goToPage(props.totalPages)">{{ props.totalPages }}</a>
        </li>
  
        <li class="page-item">
          <a
            class="page-link"
            :class="{ 'disabled': props.currentPage === props.totalPages }"
            href="#"
            aria-label="Next"
            @click.prevent="goToPage(props.currentPage + 1)"
          >
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </template>
  
  <script setup>
  import { computed, defineProps, defineEmits } from 'vue';
  
  const props = defineProps({
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    maxVisiblePages: {
      type: Number,
      default: 7
    }
  });
  
  const emit = defineEmits(['page-change']);
  
  const middlePages = computed(() => {
    const middleCount = props.maxVisiblePages - 2; 
    let start, end;
  
    if (props.totalPages <= props.maxVisiblePages) {
      start = 2;
      end = props.totalPages - 1;
    } else if (props.currentPage <= Math.ceil(middleCount / 2) + 1) {
      start = 2;
      end = middleCount + 1;
    } else if (props.currentPage >= props.totalPages - Math.floor(middleCount / 2)) {
      start = props.totalPages - middleCount;
      end = props.totalPages - 1;
    } else {
      start = props.currentPage - Math.floor(middleCount / 2);
      end = props.currentPage + Math.ceil(middleCount / 2) - 1;
    }
  
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  });
  
  const showLeftEllipsis = computed(() => {
    return props.currentPage > Math.ceil(props.maxVisiblePages / 2) + 1 && props.totalPages > props.maxVisiblePages;
  });
  
  const showRightEllipsis = computed(() => {
    return props.currentPage < props.totalPages - Math.floor(props.maxVisiblePages / 2) && props.totalPages > props.maxVisiblePages;
  });
  
  const goToPage = (page) => {
    if (page >= 1 && page <= props.totalPages && page !== props.currentPage) {
      emit('page-change', page);
    }
  };
  </script>