<template>
  <div>
    <div class="adminTableBox">
      <div
        class="container-fluid responsive"
        :class="type == 'Admin' ? 'tableAdmin' : 'table'"
        style="overflow-x: auto"
      >
        <!-- <div class="table__head d-flex"> -->
        <section v-if="type == 'Admin'">
          <UsersCustomTableForAdmin
            :data="props.data"
            :allUsersItems="allUsersItems"
            @getLicenseKeyId="(id) => (renewIndex = id)"
            @editSubUser="editSubUser"
            @loginUserPage="loginUserPage"
          ></UsersCustomTableForAdmin>
        </section>
        <section v-else-if="type === 'User'">
          <UsersCustomTableForUser
            :data="props.data"
            :allUsersItems="allUsersItems"
            @editSubUser="editSubUser"
            @getLicenseKeyId="(id) => (renewIndex = id)"
          ></UsersCustomTableForUser>
        </section>
        <!-- </div> -->

        <DefaultActionModal
          :header="'Disabled license key'"
          :body="`Are you sure you want to disabled this ${
            allUsersItems[renewIndex]?.role == 'User' ? 'user' : 'sub-user'
          }'s license key?`"
          :footer="'Delete'"
          :index="renewIndex"
          class="modal fade"
          id="staticDefaultModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticDefaultModalLabel"
          aria-hidden="true"
          @confirm="confirmDeactivatingUser"
        ></DefaultActionModal>
        <DefaultActionModal
          :header="'Reactivation Sub-User'"
          :body="`Are you sure you want to reactivate ${
            allUsersItems[renewIndex]?.role == 'User' ? 'user' : 'sub-user'
          }'s license key?`"
          :footer="'Confirm'"
          :index="renewIndex"
          class="modal fade"
          id="staticDefaultModal1"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticDefaultModalLabel"
          aria-hidden="true"
          @confirm="confirmActivatingUser"
        ></DefaultActionModal>
        <EditSubUserModal
          :data="editSubUserData"
          :userType="props.type == 'Admin' ? 'Admin' : 'User'"
          id="editSubUserModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="editSubUserModalLabel"
          aria-hidden="true"
          @loginUserPage="loginUserPage"
          @editUserStatus="(userData) => emits('editUserStatus', userData)"
        ></EditSubUserModal>
      </div>
    </div>
    
    
 
  <div class="w-100 mt-2 d-flex justify-content-end">
<AdvancedPagination 
  :current-page="currentPage"
  :total-pages="pageCount"
  :max-visible-pages="9"
  @page-change="goToPage"
  />
</div>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  ref,
  computed,
  onMounted,
  watch,
} from "vue";
import DefaultActionModal from "../popups/DefaultActionModal.vue";
import EditSubUserModal from "../popups/EditSubUsersModal.vue";
import UsersCustomTableForUser from "./UsersCustomTableForUser.vue";
import UsersCustomTableForAdmin from "./UsersCustomTableForAdmin.vue";
import AdvancedPagination from "./AdvancedPagination.vue";
import { useStore } from "vuex";

const props = defineProps({
  data: Object,
  allUsers: Object,
  type: String,
});
const allUsers = computed(() => props.allUsers);
const allUsersItems = computed(() => allUsers.value.items);
const allUsersMeta = computed(() => allUsers.value.meta);

const store = useStore();
const emits = defineEmits([
  "deactivateLicenseKeySubUser",
  "reactivateLicenseKey",
  "editSubUser",
  "getUsersPerPage",
  "editUserStatus",
  "loginUserPage",
]);
// const deleteIndex = ref();
const renewIndex = ref();
// let currentPage = computed(() => allUsersMeta.value.currentPage);
// const perPage = computed(() => allUsersMeta.value.itemsPerPage);
// const pageCount = computed(() => allUsersMeta.value.itemsPerPage);



const itemsPerPage = 10;

const currentPage = ref(1);
const totalRecords = computed(() => allUsersMeta.value.totalItems);
const pageCount = computed(() => Math.ceil(totalRecords.value / itemsPerPage));

// const goToPage = (numPage) => {
//   if (numPage !== currentPage.value && numPage <= pageCount.value) {
//     emits("getUsersPerPage", numPage, itemsPerPage);
//   }
// };

const goToPage = (page) => {
  currentPage.value = page;
  emits("getUsersPerPage", page, itemsPerPage);
};
// const backPage = () => {
//   if (currentPage.value > 1) {
//     emits("getUsersPerPage", currentPage.value - 1, itemsPerPage);
//   }
// };

// const nextPage = () => {
//   if (currentPage.value < pageCount.value) {
//     emits("getUsersPerPage", currentPage.value + 1, itemsPerPage);
//   }
// };


let editSubUserData = ref();
const singleDataUserForAdmin = computed(
  () => store.getters.getSingleDataUserForAdmin
);
// const singleDataUserErrorMessageForAdmin = computed(() => store.getters.getSingleDataUserErrorMessageForAdmin)
// const singleDataUserLoadingForAdmin = computed(() => store.getters.getSingleDataUserLoadingForAdmin)

const editSubUser = (index) => {
  let findUserData = allUsersItems.value.find((item) => item.id == index);
  if (props.type === "Admin") {
    // editSubUserData.value = {
    //   id: findUserData?.id,
    //   fullName:findUserData?.fullName,
    //   role: findUserData?.role,
    //   status: findUserData.subscriptionStatus
    // }
    store.dispatch("singleUserForAdmin", findUserData?.id);
  } else {
    editSubUserData = {
      id: findUserData?.id,
      fullName: findUserData?.fullName,
      role: findUserData?.role,
      status: findUserData.subscriptionStatus,
    };
  }

  // if (props.type === "Admin") {
  //   editSubUserData.value = {
  //     id: allUsersItems.value[index].id,
  //     firstName: allUsersItems.value[index]?.firstName,
  //     lastName: allUsersItems.value[index]?.lastName,
  //     parentData: allUsersItems.value[index].parentData
  //       ? allUsersItems.value[index].parentData
  //       : null,
  //     role: allUsersItems.value[index].role,
  //     status: allUsersItems.value[index].status,
  //   };
  //   // getSubUserLicense(allUsersItems.value[index].id);invitedUser.
  // } else {
  //   editSubUserData.value = {
  //     id: allUsersItems.value[index].invitedUser.id,
  //     // invitedUserId: allUsersItems.value[index].invitedUser.id,
  //     firstName: allUsersItems.value[index]?.invitedUser.firstName,
  //     lastName: allUsersItems.value[index]?.invitedUser.lastName,
  //     licenseKey: allUsersItems.value[index]?.licenseKey,
  //     licenseKeyId: allUsersItems.value[index]?.licenseKeyId,
  //     role: allUsersItems.value[index].invitedUser.role,
  //     status: allUsersItems.value[index].invitedUser.status,
  //   };
  // }
};
const loginUserPage = (index) => {
  emits("loginUserPage", index);
};

const confirmDeactivatingUser = (index) => {
  let user = allUsersItems.value.find((item) => item.licenseKeyId == index);
  if (allUsersItems.value.length == 1) {
    emits(
      "deactivateLicenseKeySubUser",
      index,
      currentPage.value - 1,
      user.invitedUser.role
    );
  } else {
    emits(
      "deactivateLicenseKeySubUser",
      index,
      currentPage.value,
      user.invitedUser.role
    );
  }
};
// const confirmDeactivatingUser = (index) => {
//   let user = allUsersItems.value.find((item) => item.id == index);                                                                                                                                                                                
//   if (allUsersItems.value.length == 1) {
//     emits(
//       "deactivateLicenseKeySubUser",
//       index,
//       currentPage.value - 1,                                                                                                            
//       user.role                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
//     );
//   } else {
//     emits(
//       "deactivateLicenseKeySubUser",
//       index,
//       currentPage.value,
//       user.role
//     );
//   }
// };
const confirmActivatingUser = (index) => {
  let user = allUsersItems.value.find((item) => item.licenseKeyId == index);
  if (allUsersItems.value.length == 1) {
    emits(
      "enabledLicenseKeySubUser",
      index,
      currentPage.value - 1,
      user.invitedUser.role
    );
  } else {
    emits(
      "enabledLicenseKeySubUser",
      index,
      currentPage.value,
      user.invitedUser.role
    );
  }
};

watch(
  () => singleDataUserForAdmin.value,
  () => {
    editSubUserData.value = singleDataUserForAdmin.value;
  },
  () => allUsersMeta.value,
  (newMeta) => {
    if (newMeta && newMeta.currentPage) {
      currentPage.value = newMeta.currentPage;
    }
  }
);

onMounted(() => {});
</script>

<style lang="scss" scoped>
.container-fluid {
  margin: 0;
  text-align: left;
}
.table {
  margin-top: 23px;
  padding: 0 36px;
  background-color: #ffffff;
  padding: 0 0 0 15px !important;
  margin: 0 !important;
  border-left: 0;

  thead {
    th {
      word-break: break-all;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      padding: 15px 5px;
    }
  }
  tbody {
    td {
      word-break: break-all;
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      color: #333333;
      padding: 15px 5px;
    }
  }
  &__head {
    &--title {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      height: 64px;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__body {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    &--actions {
      display: flex;
      min-height: 55.5px;
      z-index: 1000;
      flex-direction: row;
      gap: 34px;
      position: sticky !important;
      right: -145px !important;

      background-color: white;
      div {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
    }
  }
  .row {
    padding: 0 12px;
    align-items: center;
    height: 64px;
    div {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
.tableAdmin {
  border-radius: 10px;
  thead,
  tbody tr {
    display: table;
    width: calc(3500px - 1em);
    table-layout: fixed;
  }

  margin-top: 23px;
  background-color: #ffffff;
  margin: 0 !important;

  thead {
    width: calc(3500px - 1em);

    th {
      word-break: break-all;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      padding: 15px 5px;
    }
  }
  tbody {
    width: calc(3500px - 1em);
    tr {
      border-bottom: 1px solid #e9ecf4;
    }
    td {
      word-break: break-all;
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      color: #333333;
      padding: 15px 5px;
      border-bottom: none !important;
    }
  }
  &__head {
    &--title {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      height: 64px;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__body {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    &--actions {
      display: flex;
      min-height: 55.5px;
      z-index: 1000;
      flex-direction: row;
      gap: 34px;
      position: sticky !important;
      right: -145px !important;
      position: sticky !important;
      right: -145px !important;
      background-color: white;
      div {
        cursor: pointer;
      }
    }
  }
  .row {
    padding: 0 12px;
    align-items: center;
    height: 64px;
    border-bottom: 1px solid #e9ecf4;
    div {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
.adminTableBox {
  border-radius: 10px;
  border: 1.5px solid #e9ecf4;
  background-color: #fff;
  padding: 0 15px 15px 15px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;
  gap: 10px;
  li {
    a {
      border-radius: 8px;
    }
  }
}

.disableLink {
  color: gray !important;
}
.enableeLink {
  color: #0a58ca !important;
}

#pencil:hover,
#profile:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(14%) saturate(3425%)
    hue-rotate(190deg) brightness(94%) contrast(90%);
}
#recycle:hover {
  filter: brightness(0) saturate(100%) invert(29%) sepia(98%) saturate(7173%)
    hue-rotate(356deg) brightness(103%) contrast(118%);
}
::-webkit-scrollbar {
  height: 6px; /* height of horizontal scrollbar ← You're missing this */
  width: 6px; /* width of vertical scrollbar */
  border: 1px solid #a3adcd;
  background: #e9ecf4;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  border-radius: 10px !important;
}
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px !important;
  background-color: #a3adcd;
  cursor: pointer;
}
td:hover {
  overflow: visible;
  z-index: 100;
  backdrop-filter: blur(5px);
}
.sticky {
  position: sticky;
  right: -145px;
  background-color: white;
}
</style>