<template>
  <div
    class="modal fade"
    @focusout="emits('outsideClick')"
    tabindex="0"
    data-keyboard="false"
    data-backdrop="static"
    id="staticBackdrop"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div
        class="modal-content"
        :class="isAutoRefill ? 'autorefill' : 'switchChecked'"
      >
        <div class="d-flex w-100 justify-content-between auto-title">
          <div>
            <h2 class="modal-title text-start" id="staticBackdropLabel">
              {{ isAutoRefill ? 'Auto-Refill' : 'One Time Credit Purchase' }}
            </h2>
            <div class="modal-otherSubtitle" v-if="!isAutoRefill">
              <h6>
                Enable Auto-Refill to add credits when your balance gets low.
              </h6>
            </div>
          </div>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              v-model="isAutoRefill"
              type="checkbox"
              id="switchToAutorefill"
            />
            <label
              class="form-check-label"
              for="switchToAutorefill"
              style="font-size: 14px"
            >
              {{ isAutoRefill ? 'Switch to One-Time Purchase' : 'Switch to Auto-Refill' }}
            </label>
          </div>
        </div>
        <div class="modal-subcontent">
          <div class="modal-header d-flex flex-column text-start align-items-start">
            <div class="modal-subtitle" style="width: 100%">
              <div class="modal-subtitle__text">
                {{ getSubtitleText }}
              </div>
              <div class="modal-subtitle__select">
                <label for="selectCount" style="font-size: 13px; color: #848484;">Add Hours</label>
                <input
                  type="number"
                  v-model="countAmount"
                  @keypress="handleKeyPress"
                  id="selectCount"
                  maxlength="7"
                  :placeholder="creditType === 'hours' ? 'Enter hours' : 'Enter words'"
                />
              </div>
              <div class="modal-body">
                {{ modalText }}
              </div>
            </div>
          </div>
          <div class="modal-bodyPayment">
            <fieldset>
              <legend>Payment method</legend>
              <div v-if="paymentMethodsLoading">
                Loading payment method...
              </div>
              <div v-else-if="paymentMethodsError">
                Error loading payment method: {{ paymentMethodsError }}
              </div>
              <div v-else-if="paymentMethods">
                <div v-if="paymentMethods.paymentMethodType === 'Card'" class="radio-group-div">
                  <input
                    type="radio"
                    id="currentPaymentMethod"
                    name="payment"
                    :value="paymentMethods.id"
                    v-model="selectedPaymentMethod"
                    hidden
                  />
                  <label for="currentPaymentMethod" class="payment-method-label">
                    <img :src="getCardLogo(paymentMethods.paymentType)" 
                        :alt="paymentMethods.paymentType" 
                        width="70" 
                    />
                    <div class="payment-info">
                      <p class="payment-name">
                        {{ paymentMethods.paymentMethodName }}
                      </p>
                      <p class="payment-details">
                        •••• {{ paymentMethods.cardLastDigits }}, 
                        {{ formatExpiryDate(paymentMethods.expireMonth, paymentMethods.expireYear) }}
                      </p>
                    </div>
                    <div class="checkmark">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-check"><circle fill="#007bff" cx="12" cy="12" r="10"/><path fill="#fff" d="m9 12 2 2 4-4"/></svg>
                    </div>
                  </label>
                </div>
                <div v-else-if="paymentMethods.paymentMethodType === 'PayPal'" class="radio-group-div">
                  <input
                    type="radio"
                    id="currentPaymentMethod"
                    name="payment"
                    :value="paymentMethods.id"
                    v-model="selectedPaymentMethod"
                    hidden
                  />
                  <label for="currentPaymentMethod" class="payment-method-label">
                    <img :src="getCardLogo('PayPal')" 
                        alt="PayPal" 
                        width="60" 
                    />
                    <div class="payment-info">
                      <p class="payment-name mb-0">
                        {{ paymentMethods.paymentMethodName }}
                      </p>
                      <p class="payment-details">
                        {{ paymentMethods.paymentEmail }}
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div v-else>
                No payment method available.
              </div>
            </fieldset>
          </div>
        </div>
        <div class="modal-footer">
          <div :title="!isPaymentMethodAvailable ? 'Please add a payment method to proceed' : ''">
            <button
              type="button"
              class="btn payment-btn"
              :class="[
                isAutoRefill ? 'autorefill autorefill-btn' : 'switchChecked switchChecked-btn',
                { 'is-loading': isLoading }
              ]"
              @click="handlePayment"
              :disabled="!isPaymentMethodAvailable || isLoading"
            >
            <span class="button-content">
              <span v-if="isLoading" class="spinner"></span>
              <span class="button-text">{{ isLoading ? 'Processing...' : `Pay $ ${price}` }}</span>
            </span>
            </button>
          </div>
          <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, defineExpose } from "vue";
import { watch, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const errorMessage = ref('');
const userData = computed(() => store.getters.getCurrentUser);

const props = defineProps({
  initialMode: {
    type: String,
    default: 'oneTime'
  },
  creditType: {
    type: String,
    required: true
  },
  modalText: {
    type: String,
    required: true
  },
  isLoading: Boolean,
  modelValue: Boolean,
});
const emits = defineEmits(['update:modelValue', 'outsideClick', 'modeChange', 'paymentAttempted', 'modalClosed', 'startLoading', 'stopLoading']);
const isAutoRefill = ref(props.initialMode === 'autoRefill');
const countAmount = ref(10);

const creditType = ref(props.creditType);

const paymentMethods = computed(() => store.getters['subscriptions/getPaymentMethods']);
const paymentMethodsLoading = computed(() => store.getters['subscriptions/getPaymentMethodsLoading']);
const paymentMethodsError = computed(() => store.getters['subscriptions/getPaymentMethodsError']);
const subscription = computed(() => userData.value?.subscription?.product?.productName || '');

const isPaymentMethodAvailable = computed(() => {
  return !!paymentMethods.value;
});



const selectedPaymentMethod = ref('');

const getProductId = () => {
  const isProfessional = subscription.value.includes('Professional License');
  const isHourType = props.creditType === 'hours';

  if (isProfessional) {
    return isHourType ? 8 : 9;
  } else {
    return isHourType ? 6 : 7;
  }
};

const closeModal = () => {
  emits('update:modelValue', false);
  emits('modalClosed');
};
const handlePayment = async () => {
  emits('startLoading');
  try {
    const result = await store.dispatch(
      isAutoRefill.value ? 'setAutoRefillCredits' : 'addExtraCredits',
      {
        productId: getProductId(),
        quantity: countAmount.value.toString()
      }
    );

    emits('paymentAttempted', {
      success: result.success,
      isAutoRefill: isAutoRefill.value,
      creditType: props.creditType,
      amount: countAmount.value,
      error: result.error
    });

    if (result.success) {
      closeModal();
    }
  } catch (error) {
    console.error('Error processing payment:', error);
    emits('paymentAttempted', {
      success: false,
      isAutoRefill: isAutoRefill.value,
      creditType: props.creditType,
      amount: countAmount.value,
      error: 'An unexpected error occurred. Please try again.'
    });
  } finally {
    emits('stopLoading');
  }
};

defineExpose({ closeModal });

const getSubtitleText = computed(() => {
  const amount = countAmount.value || 0;
  const isHourType = props.creditType === 'hours';
  const isProfessional = subscription.value.includes('Professional License');
  const hourText = amount > 1 ? 'hours' : 'hour';
  const wordAmount = amount * 750;

  if (isAutoRefill.value) {
    if (isProfessional) {
      return isHourType
        ? `Automatically add ${amount} ${hourText} at $5.00/hr to my usage, once my credits are used up.`
        : `Automatically add ${wordAmount} words at $0.30/750 words to my usage, once my credits are used up.`;
    } else {
      return isHourType
        ? `Automatically add ${amount} ${hourText} at $2.50/hr to your usage, once your credits are used up.`
        : `Automatically add ${wordAmount} words at $0.20/750 words to your usage, once your credits are used up.`;
    }
  } else {
    if (isProfessional) {
      return isHourType
        ? `Add ${amount} ${hourText} at $5.00/hr to my usage`
        : `Add ${wordAmount} words at $0.30/750 words to my usage`;
    } else {
      return isHourType
        ? `Add ${amount} ${hourText} at $2.50/hr to your usage`
        : `Add ${wordAmount} words at $0.20/750 words to your usage`;
    }
  }
});

const price = computed(() => {
  const amount = countAmount.value || 0;
  const isHourType = props.creditType === 'hours';
  const isProfessional = subscription.value.includes('Professional License');

  if (isHourType) {
    return isProfessional ? amount * 5 : amount * 2.5;
  } else {
    return isProfessional ? amount * 0.3 : amount * 0.2;
  }
});

// const subtitleText2 = computed(() => {
//   const amount = countAmount.value ? countAmount.value : "0";
  
//   if (subscription.value.includes('Professional License')) {
//     return creditType.value === "hours"
//       ? `Automatically add ${amount} hours at $5.00/hr to my usage, once my credits are used up.`
//       : `Automatically add ${amount * 750} words at $0.30/750 words, to my usage, once my credits are used up.`;
//   } else {
//     return creditType.value === "hours"
//       ? `Automatically add ${amount} hours at $2.50/hr to your usage, once your credits are used up.`
//       : `Automatically add ${amount * 750} words at $0.20/750 words, to your usage, once your credits are used up.`;
//   } 
// });


const handleKeyPress = (e) => {
  if (countAmount.value.toString().length >= 7) {
    e.preventDefault();
  }
  if (e.charCode >= 32 && (e.charCode < 48 || e.charCode > 57)) {
    e.preventDefault();
  }
};

function getCardLogo(paymentType) {
  const type = paymentType.toLowerCase();
  if (type === 'amex') {
    return require('@/assets/amex.png');
  } else if (type === 'visa') {
    return require('@/assets/visa.png');
  } else if (type === 'mastercard') {
    return require('@/assets/mastercard.png');
  } else if (type === 'paypal') {
    return require('@/assets/paypal.png');
  }
  return ''; 
}

const formatExpiryDate = (month, year) => {
  if (!month || !year) return 'MM/YY';
  const formattedMonth = month.toString().padStart(2, '0');
  const formattedYear = year.toString().slice(-2);
  return `${formattedMonth}/${formattedYear}`;
};

watch(isAutoRefill, (newValue) => {
  emits('modeChange', newValue ? 'autoRefill' : 'oneTime');
});

watch(() => props.initialMode, (newMode) => {
  isAutoRefill.value = newMode === 'autoRefill';
});

onMounted(() => {
  store.dispatch('subscriptions/fetchPaymentMethods');
});
</script>
<style lang="scss" scoped>
.modal-dialog {
  left: -130px !important;
  width: 100% !important;
  height: 100% !important;

}

.modal-subtitle__text {
  font-size: 15px;
  font-weight: 600;
  max-width: 40ch;
}

.payment-name {
  margin-bottom: 0;
  font-weight: 600;
  color: #1d2031;
  font-size: 15px;
  font-family: Nunito Medium;
}
.payment-details {
  font-family: Nunito Medium;
}

.modal-content {
  color: #1d2031 !important;
  max-width: 799px !important;
  --bs-modal-width: 799px !important;
  width: 799px !important;
  border: none !important;
  padding-top: 30px;
  border-radius: 10px !important;
  padding-inline: 20px;
  .modal-header {
    gap: 14px !important;
    border: none !important;
  }
  .modal-subcontent {
    background: white !important;
    display: flex;
    align-items: start;
    gap: 15px;
    width: 100%;

  }

  .modal-otherSubtitle {
    margin-bottom: 6px;
    h6 {
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      color: #848484;
      max-width: 60ch;
      text-align: start;
    }
  }
  .modal-subtitle {
    display: flex;
    width: 100%;
    gap: 15px;
    max-width: 400px;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    font-weight: 400 !important;
    &__text {
      width: 457px;
    }
    &__select {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 16px;

      h6 {
        font-weight: 400;
        line-height: 24px;
        font-size: 13px;
      }
      select {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
      input {
        width: 179px;
        height: 45px;
        border: 1px solid #edeff7;
        border-radius: 6px;
        font-size: 14px;
        padding: 0 10px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
      }
      input:focus {
        outline: none;
      }
      input::placeholder {
        color: #848484 !important;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 21px;
        margin-left: 5px !important;
      }
    }
  }
  .modal-body {
    font-size: 13px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    padding: 0 !important;
  }
  .modal-bodyPayment {
    fieldset {
      margin-top: 14px;
      text-align: left;
      margin-bottom: 38px;
      legend {
        color: #1d2031;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 29px;
      }
    }
    div {
      display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: start;

      div {
        gap: 8px !important;
      }
      label {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
      }
      input {
        width: 16px;
        height: 16px;
      }
    }
  }

  .modal-footer {
    display: flex !important;
    flex-direction: column !important;
    gap: 17px !important;
    margin-top: 10px !important;
    text-align: left !important;
    align-items: center !important;
    justify-content: end;
    border: none !important;
    button {
      min-width: 170px !important;
      height: 40px !important;
      color: #fff !important;
      border-radius: 35px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      color: #ff1f00 !important;
      font-size: 13px !important;
      font-weight: 300 !important;
      line-height: 18px !important;
    }
  }
  h2 {
    font-weight: 600 !important;
    text-align: left !important;
  }
  h2,
  .modal-subtitle,
  .modal-footer > button {
    font-size: 20px;
    line-height: 24px !important;
  }
}

.radio-group-div {
  margin-bottom: 15px;
}

.payment-method-label {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.payment-method-label:hover {
  border-color: #007bff;
}

.payment-info {
  flex-grow: 1;
  font-family: Nunito Medium;
}

.payment-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.payment-details {
  color: #666;
  font-size: 0.9em;
  margin-bottom: 0;
}

.auto-title {
  border-bottom: 0.8px solid #e0e0e0;
}

.switchChecked-btn {
  background-color: #2b62ff;
  padding: 10px 15px !important;
  font-size: 16px !important;
  border-radius: 24px;
  color: #fff;
  transition: all 0.3s ease;
}
.autorefill-btn {
  background-color: #03d670;
  padding: 10px 15px !important;
  font-size: 16px !important;
  border-radius: 24px;
  color: #fff;
  transition: all 0.3s ease;
}

.switchChecked-btn:hover {
  background-color: #007bff;
  scale: 105%;
}

.autorefill-btn:hover {
  background-color: #03d670;
  scale: 105%;
}

// .checkmark {
//   width: 22px;
//   height: 22px;
//   border: 2px solid #e0e0e0;
//   border-radius: 50%;
//   margin-left: auto;
//   position: relative;
//   transition: all 0.3s ease;
// }

.checkmark {
  display: none;
}

input[type="radio"]:checked + .payment-method-label {
 
  background-color: #deedfb;
}

input[type="radio"]:checked + .payment-method-label .checkmark {
  display: block;
}

// input[type="radio"]:checked + .payment-method-label .checkmark::after {
//   content: '';
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 12px;
//   height: 12px;
//   background-color: white;
//   border-radius: 50%;
// }

// .autorefill {
//   background: #69c900;
// }

.switchChecked {
 padding: 30px 40px;
}

.autorefill > .auto-title {
  padding-inline: 30px;
  padding-bottom: 20px;
  border-bottom: .8px solid #e0e0e0;
}

// .autorefill > .modal-subcontent {
//   flex-direction: column;
// }

.autorefill  .modal-header {
  align-items: center !important;
}

.autorefill .modal-subtitle__text {
  max-width: 100%;
  width: 100% !important;
}

@media(max-width: 768px) {
  .modal-content .modal-subcontent {
    flex-direction: column;
  }
  .modal-dialog {
  left: 0px !important;
  width: 100% !important;
  height: 100% !important;

}
.modal-content {
  width: 100% !important;
  max-width: 100% !important;
}
}
.payment-btn {
  position: relative;
  min-width: 150px;
  transition: all 0.3s ease;
}

.payment-btn .button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.payment-btn .spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 0.8s linear infinite;
}

.payment-btn.is-loading {
  opacity: 0.8;
  cursor: not-allowed;
}

.payment-btn.is-loading .button-text {
  opacity: 0.8;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
