<template>
  <div class="billing">
    <div class="billing__body">
      <section class="myAccHead bilingHead">
        <div class="container-fluid contentContainer">
          <div class="row">
            <div class="detailNav bilingNav">
              <a href="#"
                ><img
                  src="../assets/BillingIcon.png"
                  class="img-fluid"
                  alt="img"
                />Billing/Plan</a
              >
            </div>
            <div class="hr-line" />
            <BillingHistory v-if="userDataRole === 'User'" class="mt-5 d-none"></BillingHistory>
          </div>
          <div class="row">
            <div class="bilingContent">
              <div class="BilingText">
                <h2>Subscriptions</h2>
                <div class="line" />
              </div>

              <div class="table-responsive text-start pt-5" >
                <table class="table table-borderless">
                  <thead class="BilingList">
                    <tr>
                      <th scope="col">
                        <div class="listInnerTxt">
                          <a>Plan Name</a>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="listInnerTxt">
                          <a>Amount</a>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="listInnerTxt">
                          <a>FEATURES INCLUDED</a>
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          class="listInnerTxt d-flex gap-2 align-items-center"
                        >
                          <a >USAGE THIS MONTH</a>
                          <a  v-tooltip="'Current credit usage this period'">
                          <img
                            src="@/assets/question_mark.svg"
                            style="margin-bottom: 2px"
                            alt="question"
                            class="question-icon"
                          />
                        </a>
                        </div>
                      </th>
                      <th scope="col" v-if="hasExtraCredits">
                        <div
                          class="listInnerTxt d-flex gap-2 align-items-center"
                        >
                          <a>Extra Credits</a>
                          <img
                            src="@/assets/question_mark.svg"
                            style="margin-bottom: 2px"
                            alt="question"
                             class="question-icon"
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          class="listInnerTxt d-flex gap-2 align-items-center"
                        >
                          <a>ADD-ON CREDITS</a>
                          <a  v-tooltip="'Add credits as a one-time purchase'">
                          <img
                            src="@/assets/question_mark.svg"
                            style="margin-bottom: 2px"
                            alt="question"
                             class="question-icon"
                          />
                        </a>
                        </div>
                      </th>
                      <th scope="col" style="min-width: 170px">
                        <div
                          class="listInnerTxt d-flex gap-2 align-items-center"
                        >
                          <a>AUTO-REFILL</a>
                          <a  v-tooltip="'Automatically add credits once all are used'">
                          <img
                            src="@/assets/question_mark.svg"
                            style="margin-bottom: 2px"
                            alt="question"
                             class="question-icon"
                          />
                          </a>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="">
  <tr>
    <th scope="row">
      <div class="bilingFotterInnertxt">
        <p v-if="userData.role === 'User'">{{ userData?.subscription?.product?.productName }}</p>
        <p v-if="userData.role === 'Admin'">Admin Account</p>
      </div>
    </th>
    <td>
      <div class="bilingFotterInnertxt">
        <p class="bilingFotteramount" v-if="userData?.subscription?.product?.productName?.includes('Creative Club (Yearly)')"><span>$297/</span>yr</p>
    <p class="bilingFotteramount" v-else-if="userData?.subscription?.product?.productName?.includes('Creative Club')"><span>$29/</span>mo</p>
    <p class="bilingFotteramount" v-else-if="userData?.subscription?.product?.productName?.includes('Professional License')"><span>$67</span></p>
      </div>
    </td>
    <td>
      <div class="bilingFotterInnertxt">
        <p><span>AI-Powered Audio/Text Sync</span></p>
      </div>
    </td>
    <td>
    <div class="bilingFotterInnertxt">
      <p style="font-size: 15px; font-weight: 500">
        <span>{{ deepGramUsageThisMonth }} of {{ maxHours }} hours</span>
      </p>
      <p v-if="!isProfessionalLicense" style="font-size: 11px">
        <span>Resets on {{ resetDate }}</span>
      </p>
    </div>
  </td>
    <td v-if="hasExtraCredits">
    <div class="bilingFotterInnertxt">
      <p style="font-size: 15px; font-weight: 500">
        <span style="font-weight: 500; font-size: 18px;">{{ audioTextExtraHours }} hour</span>
      </p>
    </div>
  </td>
    <td>
      <div class="bilingFotterInnertxt d-flex gap-3 align-items-center">
        
        <button
          @click="showModal('hours', 'One Time Credit Purchase')"
          type="button"
          class="addButton"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
         + Add More Hours 
        </button>
      </div>
    </td>
    <td>
      <div class="bilingFotterInnertxt">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="isAutoRefillHours"
            type="checkbox"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            id="flexSwitchCheckChecked"
            @change="switchToAutoRefill('hours')"
          />
          <label
            class="form-check-label"
            for="flexSwitchCheckChecked"
            style="font-size: 14px"
            >{{ isAutoRefillHours ? "10 hours" : "" }}</label
          >
        </div>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2"></td>
    <td>
      <div class="bilingFotterInnertxt">
        <p>AI-Powered Script Creator</p>
      </div>
    </td>
    <td>
    <div class="bilingFotterInnertxt">
      <p style="font-size: 15px; font-weight: 500">
        <span>{{ chatGptUsageThisMonth }} of {{ maxWords }} words</span>
      </p>
      <p v-if="!isProfessionalLicense" style="font-size: 14px">
        <span>Resets on {{ resetDate }}</span>
      </p>
    </div>
  </td>
    <td v-if="hasExtraCredits">
    <div class="bilingFotterInnertxt">
      <p style="font-size: 15px; font-weight: 500">
        <span style="font-weight: 500; font-size: 18px;">{{ scryptGeneratorExtra }} words</span>
      </p>
    </div>
  </td>
    <td>
      <div class="bilingFotterInnertxt d-flex gap-3 align-items-center">
        <button
          @click.stop="showModal('words', 'One Time Credit Purchase')"
          type="button"
          class="addButton"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
         + Add More Words
        </button>
      </div>
    </td>
    <td>
      <div class="bilingFotterInnertxt refill">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="isAutoRefillWords"
            type="checkbox"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            id="flexSwitchCheckChecked"
            @change="switchToAutoRefill('words')"
          />
          <label
            class="form-check-label"
            for="flexSwitchCheckChecked"
            style="font-size: 14px"
            >{{ isAutoRefillWords ? "10,000 words" : "" }}</label
          >
        </div>
      </div>
    </td>
  </tr>
</tbody>
                </table>
                <div
                  class="d-flex justify-content-between"
                  style="margin-left: 35px"
                >
                  <div v-if="userData?.subscription?.product?.productName?.includes('Creative Club')" class="cancelButton" @click="openCancelModal">Cancel Subscription</div>
                  <div class="downloadButton" @click="downloadUsageHistory">
                    Download Usage History
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
        <div class="paymentBox">
          <div class="PaymentText">
            <h2>Payment Methods</h2>
            <div class="line" />
          </div>

        <div class="table-responsive">
          <div class="payment">
            <div class="payment__header">
              <div class="payment__header-type">Type</div>
              <div class="payment__header-actions">Actions</div>
            </div>
            <div class="payment__body">
    <div v-if="paymentMethodsLoading" class="payment__body--row">
      Loading payment methods...
    </div>
    <div v-else-if="paymentMethods" class="payment__body--row">
      <div v-if="paymentMethods.paymentMethodType === 'Card'" class="">
        <div class="payment__body--row__type">
          <div>
            <img
              :src="getCardLogo(paymentMethods.paymentType)"
              :alt="paymentMethods.paymentType"
              class="img-fluid"
              style="width: 50px;"
            />
            <div class="payment-info">
              <span class="payment-info__last-digits">
                xxxx xxxx xxxx {{ paymentMethods.cardLastDigits }}
              </span>
              <span class="payment-info__expiry">
                Expires: {{ formatMonth(paymentMethods.expireMonth) }}/{{ paymentMethods.expireYear }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="paymentMethods.paymentMethodType === 'PayPal'" class="">
        <div class="payment__body--row__type">
          <div>
            <img
              :src="getCardLogo('PayPal')"
              alt="PayPal"
              class="img-fluid"
              style="width: 50px;"
            />
            <div class="payment-info">
              <span class="payment-info__last-digits">
                {{ paymentMethods.paymentEmail }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="payment__body--row__actions">
        <a href="https://app.paykickstart.com/billing/" target="_blank" class="update-btn change">
          Manage
        </a>
      </div>
    </div>
    <div v-else-if="paymentMethodsError" class="payment__body--row error fw-semibold text-dark">
      No payment methods available.
    </div>
    <div v-else class="payment__body--row">
      Loading payment methods...
    </div>
  </div>
          </div>
        </div>
    </div>
  </div>
        </div>
      </section>
    </div>
    <AddOnsCredentialModal
      class="modal fade"
      v-model="isModalVisible"
      @outsideClick="handleModalClose"
      @modeChange="handleModeChange"
      @paymentAttempted="handlePaymentAttempt"
      @startLoading="isLoading = true"
      @stopLoading="isLoading = false"
      @modalClosed="handleModalClose"
      id="staticBackdrop"
      data-keyboard="false"
      data-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      :initialMode="modalMode"
      :creditType="modalCreditType"
      :modalText="selectedModalValue.text"
      :isLoading="isLoading"
    ></AddOnsCredentialModal>
    <CancelSubscription
     class="modal fade"
     data-keyboard="false"
      data-backdrop="static"
       ref="cancelSubscriptionModal"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      @subscriptionCanceled="handleSubscriptionCanceled"
      aria-hidden="true"></CancelSubscription>
  </div>
</template>
<script setup>
import { onMounted, ref, computed, watch, reactive } from "vue";
import { useStore } from "vuex";
import dayjs from 'dayjs';
import { useRouter } from "vue-router";
import AddOnsCredentialModal from "@/components/popups/AddOnsCredentialModal.vue";
import CancelSubscription from "@/components/popups/CancelSubscription.vue";
import BillingHistory from "@/components/dashboard/BillingHistory.vue";
import { vTooltip } from "@/utils/tooltipDirective";
import { useToast } from "vue-toastification";

const toast = useToast();

const router = useRouter();



const store = useStore();
const userData = computed(() => store.getters.getCurrentUser);
const userDataRole = computed(() => userData.value.role);
const creditUsage = ref({});
const chatGptUsageThisMonth = computed(() => store.getters.getChatGptUsageThisMonth);
const deepGramUsageThisMonth = computed(() => store.getters.getDeepGramUsageThisMonth);
const isAutoRefillHours = ref(false);
const isAutoRefillWords = ref(false);
const isLoading = ref(false);
const modalMode = ref('oneTime');
const isModalVisible = ref(false);
const modalCreditType = ref('hours');
const selectedModalValue = ref({});




const cancelSubscriptionModal = ref(null);

const isProfessionalLicense = computed(() => 
  userData.value?.subscription?.product?.productName.includes('Professional License')
);

const audioTextExtraHours = computed(() => {
  const audioTextSeconds = userData.value?.subscription?.extraCredit?.audioText || 0;
  const hours = (audioTextSeconds / 3600).toFixed(2);
  return parseFloat(hours);
});

const scryptGeneratorExtra = computed(() => 
  userData.value?.subscription?.extraCredit?.scryptGenerator || 0
)

const hasExtraCredits = computed(() => {
  return audioTextExtraHours.value > 0 || scryptGeneratorExtra.value > 0;
})

const maxHours = computed(() => 
  isProfessionalLicense.value ? "5:00" : "200:00"
);

const maxWords = computed(() => 
  isProfessionalLicense.value ? 0 : 20000
);



const loadUserData = async () => {
  try {
    await store.dispatch('users/updateCurrentUser');
    userData.value = store.getters['users/getCurrentUser'];
  } catch (error) {
    console.error('Error loading user data:', error);

  }
};

function openCancelModal() {
  cancelSubscriptionModal.value.openModal();
}

async function handleSubscriptionCanceled() {
  try {
    await loadUserData()
    console.log('User data updated after subscription cancellation');
  } catch (error) {
    console.error('Error updating user data:', error);

  }
}


function formatCreditUsage(data) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  creditUsage.value = data.map(item => {
    const date = new Date(item.createdAt);
    const monthName = months[date.getMonth()];
    return {
      Month: monthName,
      "Audio/Text Sync (seconds)": item.audioText,
      "Script Creator (words)": item.scryptGenerator,
      Musics: item.musics,
      Illustrators: item.illustrators,
      Vectors: item.vectors,
      Photos: item.photos,
      Videos: item.videos,
      Fonts: item.fonts,
      "Subscription ID": item.subscriptionId,
      "User ID": item.userId,
      "Created At": item.createdAt,
      "Updated At": item.updatedAt
    };
  }).sort((a, b) => new Date(b["Created At"]) - new Date(a["Created At"]));
}

function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  const headers = Object.keys(array[0]).join(',');
  str += headers + '\r\n';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (line !== '') line += ',';
      line += array[i][index];
    }
    str += line + '\r\n';
  }

  return str;
}

function downloadCSV(csv, filename) {
  const csvFile = new Blob([csv], { type: 'text/csv' });
  const downloadLink = document.createElement('a');
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

function downloadUsageHistory() {
  const csv = convertToCSV(creditUsage.value);
  downloadCSV(csv, 'credit_usage_history.csv');
}

// Function to calculate reset date
const calculateResetDate = () => {
  const now = new Date();
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  return dayjs(nextMonth).format('MM/DD');
};

const paymentMethods = computed(() => store.getters['subscriptions/getPaymentMethods']);
const paymentMethodsError = computed(() => store.state.subscriptions.paymentMethodsError);
const paymentMethodsLoading = computed(() => store.getters['subscriptions/getPaymentMethodsLoading']);


const fetchPaymentMethods = async () => {
  try {
    await store.dispatch('subscriptions/fetchPaymentMethods');
  } catch (error) {
    console.error('Error fetching payment methods:', error);
  }
};




const resetDate = computed(() => calculateResetDate());

// const subscriptionPrice = computed(() => {
//   return userData.value?.subscription?.price || '$0';
// });

function getCardLogo(paymentType) {
  const type = paymentType.toLowerCase();
  if (type === 'amex') {
    return require('@/assets/amex.png');
  } else if (type === 'visa') {
    return require('@/assets/visa.png');
  } else if (type === 'mastercard') {
    return require('@/assets/mastercard.png');
  } else if (type === 'paypal') {
    return require('@/assets/PayPal-Logo1.png');
  }
  return ''; 
}

const formatMonth = (month) => {
  if (!month) return '**';
  return month.toString().padStart(2, '0');
};


const modalValues = reactive({
  hours: {
    subtitle:
      "Add 10 hours at $2.50/hr to my overage usage that will be used once my monthly allotment is used up.",
    text: "* I understand that my overage usage will never expire and can be used any month where I go over my monthly allotted usage.  My payment method on file will be used.",
    pay: "$69.00",
    errorMessage:
      "Sorry, your payment did not process. Please update your payment method on file and try again.",
    selectAmount: ["10 hours", "20 hours", "30 hours"],
  },
  words: {
    subtitle:
      "Add 10,000 words at $0.20/750 words, once my monthly allotted usage has been used.",
    text: "* I understand that my overage usage will never expire and can be used any month where I go over my monthly allotted usage.  My payment method on file will be used.",
    pay: "$69.00",
    errorMessage:
      "Sorry, your payment did not process. Please update your payment method on file and try again.",
    selectAmount: ["10000 words", "20000 words", "30000 words"],
  },
});

const showModal = (type, title) => {
  modalMode.value = title === 'Auto-Refill Credits' ? 'autoRefill' : 'oneTime';
  modalCreditType.value = type;
  selectedModalValue.value = { 
    title, 
    ...modalValues[type],
    paymentMethod: paymentMethods.value
  };
  isModalVisible.value = true;

};

const handleModalClose = () => {
  isModalVisible.value = false;
  if (modalMode.value === 'oneTime') {
    // Reset switches only if it was a one-time purchase modal
    isAutoRefillHours.value = false;
    isAutoRefillWords.value = false;
  }
};
const switchToAutoRefill = (type) => {
  modalMode.value = 'autoRefill';
  modalCreditType.value = type;
  showModal(type, 'Auto-Refill Credits');
};

const handleModeChange = (newMode) => {
  modalMode.value = newMode;
  if (newMode === 'oneTime') {
    // Reset the corresponding switch when changing to one-time mode
    if (modalCreditType.value === 'hours') {
      isAutoRefillHours.value = false;
    } else {
      isAutoRefillWords.value = false;
    }
  }
};

const handlePaymentAttempt = ({ success, isAutoRefill, creditType, amount, error }) => {
  if (success) {
    let creditTypeText;
    let displayAmount;

    if (creditType === 'hours') {
      creditTypeText = amount === 1 ? 'hour' : 'hours';
      displayAmount = amount;
    } else {
      creditTypeText = 'words';
      displayAmount = amount * 750;
    }

    let message;
    if (isAutoRefill) {
      message = `Auto-refill set up successfully. ${displayAmount} ${creditTypeText} will be added when your credits are used up.`;
    } else {
      message = `Successfully purchased ${displayAmount} ${creditTypeText}!`;
    }

    toast.success(message);
    loadUserData();
    handleModalClose(); 
  } else {
    toast.error(error || 'An error occurred during payment processing.');
  }
};

watch(isModalVisible, (newValue) => {
  console.log('Modal visibility changed:', newValue);
});


// watch(
//   () => isChecked.value,
//   () => {
//     showModal("hours", "Auto-Refill Credits");
//   }
// );

// watch(
//   () => isCheckedWords.value,
//   () => {
//     showModal("words", "Auto-Refill Credits");
//   }
// );

// onMounted(() => {
//   if (!localStorage.getItem("vsl-token")) {
//     router.push("/login");
//   }
// });


onMounted(async () => {
  if (!localStorage.getItem("vsl-token")) {
    router.push("/login");
  }
  await store.dispatch("fetchCreditUsage");
  await store.dispatch("fetchChatGptUsage");
  await store.dispatch("fetchDeepGramUsage");
  await store.dispatch("fetchCreditUsage");
  fetchPaymentMethods();
  formatCreditUsage(store.getters.getCreditUsage);
});



</script>

<style lang="scss" scoped>
.billing {
  &__body {
    position: relative;
    font-family: Nunito Medium;
  }
}

.update-btn {
  border-radius: 24px;
  font-family: Nunito Medium;
  font-weight: 600;
  background-color: #1d2031;
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  transition: all 0.3s ease ;
}

.update-btn:hover {
  background-color: #333333;
  scale: 105%;
}

.addButton {
  width: fit-content !important;
  height: auto !important;
  display: flex;
  align-items: center;
  justify-items: center;
  // background: black;
  background: #2b62ff;
  text-align: center;
  border-radius: 28px;
  border: 2px solid #2b62ff;
  padding: 4px 10px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  font-size: 11px;
  
}

.payment-info {
  display: flex;
  align-items: center;
  font-family: Nunito Medium;
}

.payment-info__last-digits {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 15px;
  
}

.payment-info__expiry {
  font-size: 14px;
  color: #666;
}

.addButton:active {
  background: #566ca8;
}

.cancelButton {
  cursor: pointer;
  color: #f44336;
  font-size: 13px;
  line-height: 24px;
  font-family: Nunito Medium;
  font-weight: 600;
}
.downloadButton {
  cursor: pointer;
  color: #2a3f7a;
  font-size: 13px;
  line-height: 24px;
  font-weight: 600;
}
.form-check-input:focus {
  border-color: #69c900 !important;
  box-shadow: 0 0 0 0.25rem rgb(105 201 0 / 25%) !important;
}
.form-switch .form-check-input {
  width: 36px !important;
  height: 20px !important;
}
.form-switch.form-check-input {
  background-color: #d4d6e0 !important;
  border-color: #d4d6e0 !important;
}
.form-switch .form-check-input:checked {
  background-color: #69c900 !important;
  border-color: #69c900 !important;
}

.modal-dialog {
  left: -130px !important;
  width: 100% !important;
  height: 100% !important;
  top: 100px !important;
}

.modal-content {
  color: #1d2031 !important;
  max-width: 799px !important;
  --bs-modal-width: 799px !important;
  width: 799px !important;

  padding-top: 40px;
  border-radius: 40px !important;
  .modal-header {
    gap: 17px !important;
    border: none !important;
  }
  .modal-subcontent {
    background: white !important;
    padding: 23px 63px 63px !important;
    // padding: 44px 63px 63px !important;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
  }
  .modal-subtitle {
    display: flex;
    width: 100%;
    gap: 60px;
    font-weight: 400 !important;
    &__text {
      width: 457px;
    }
    &__select {
      display: flex;
      flex-direction: column;
      gap: 5px;

      h6 {
        font-weight: 400;
        line-height: 24px;
        font-size: 13px;
      }
      select {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  .modal-body {
    font-size: 13px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
  }

  .modal-footer {
    display: flex !important;
    flex-direction: column !important;
    gap: 17px !important;
    margin-top: 10px !important;
    text-align: left !important;
    align-items: flex-start !important;
    border: none !important;
    button {
      width: 170px !important;
      height: 40px !important;
      color: #fff !important;
      background: #2b62ff !important;
      border-radius: 35px !important;
    }
    p {
      color: #ff1f00 !important;
      font-size: 13px !important;
      font-weight: 300 !important;
      line-height: 18px !important;
    }
  }
  h4 {
    font-weight: 600 !important;
    text-align: left !important;
  }
  h4,
  .modal-subtitle,
  .modal-footer > button {
    font-size: 20px !important;
    line-height: 24px !important;
  }
}
.payment {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 30px;
    background: #f2f6ff;
    color: #6e6893;
    font-family: Inter;
    height: 45px;
    align-items: center;
    padding: 0 30px;
    line-height: 14.52px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 5%;
    text-transform: uppercase;
    font-family: "Inter Semi Bold";
  }
  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 0 30px;
    &--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__type,
      &__actions {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: #b4bec9;
        font-family: Biryani;
        line-height: 24px;
        font-size: 14px;
        font-weight: 400;
        gap: 17px;
      }
      &__type {
        div {
          display: flex;
          align-items: center !important;
          gap: 13px;
        }
      }
      &__actions {
        button {
          width: 170px;
          height: 40px;
          border-radius: 45px;
          font-family: "Nunito Semi Bold";
        }
      }
    }
  }
}
.hr-line {
  border: 1px solid #d5dbf5;
  height: 0;
  margin-top: 12px;
}

.tooltip-trigger {
  position: relative;
}

.tooltip-trigger::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 9px;
  white-space: wrap;
  width: 110px;
  text-transform: capitalize;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.tooltip-trigger:hover::after {
  opacity: 1;
  visibility: visible;
}

.question-icon {
  cursor: pointer;
}
</style>
