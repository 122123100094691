<template>
  <div :class="props.type == 'Admin' ? 'col-sm-12 col-md-12 col-lg-6' : ''">
    <div class="summaryTitle">Summary</div>
    <div
      :class="props.type != 'Admin' ? 'summaryFlexRow' : 'summaryFlexRow'"
      v-if="!getMonthlyChartDataLoading"
    >
      <div class="summary" v-for="(data, index) in summaryData" :key="index">
        <div class="summary__card">
          <div class="summary__card--title">
            {{ data.title }}
            <span> {{ data.type }} </span>
          </div>
          <div class="summary__card--body">
            {{ data.body }}
            <span :class="data.percent < 0 ? 'danger' : 'success'">
              {{ data.percent ? `${data.percent == 'Infinity' || data.percent == 'NaN' ? 0 : data.percent} %` : "" }}
            </span>
          </div>
          <p>{{ data.text }}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <TableLoader></TableLoader>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted } from "vue";
import { useStore } from "vuex";
import TableLoader from "../utils/TableLoader.vue";

const props = defineProps({
  type: String,
  chartData: {
    type: Object,
    require: true,
  },
});

const store = useStore();
const monthlyChartData = computed(() => store.getters.getMonthlyChartData);
const getMonthlyChartDataLoading = computed(() => store.getters.getMonthlyChartDataLoading);

const summaryData = computed(() => {
  if (monthlyChartData.value) {
    const lastMonth = monthlyChartData.value.creditsMonthlyUsage[monthlyChartData.value.creditsMonthlyUsage.length - 1] || { totalScryptGenerator: 0, totalAudioText: 0 };
    const secondLastMonth = monthlyChartData.value.creditsMonthlyUsage[monthlyChartData.value.creditsMonthlyUsage.length - 2] || { totalScryptGenerator: 0, totalAudioText: 0 };
    const lastSoldMonth = monthlyChartData.value.creditsMonthlySold[monthlyChartData.value.creditsMonthlySold.length - 1] || { totalScryptGenerator: 0, totalAudioText: 0 };
    
    const ccSubscribersMonthly = monthlyChartData.value.ccSubscribersMonthly;
    const ccSubscriberCount = ccSubscribersMonthly.length > 0 
      ? ccSubscribersMonthly[ccSubscribersMonthly.length - 1].subscriberCount 
      : 0;

    const allSubscribersMonthly = monthlyChartData.value.allSubscribersMonthly;
    const allSubscriberCount = allSubscribersMonthly.length > 0 
      ? allSubscribersMonthly[allSubscribersMonthly.length - 1].subscriberCount 
      : 0;

      return [
        {
          title: "ChatGPT",
          body: `${Number(lastMonth.totalScryptGenerator).toLocaleString('en-US')} words`, 
          percent: calculatePercentChange(secondLastMonth.totalScryptGenerator, lastMonth.totalScryptGenerator),
          type: "monthly usage",
          text: `Total sold this month: ${Number(lastSoldMonth.totalScryptGenerator).toLocaleString('en-US')} words`, 
        },
        {
          title: "DeepGram",
          body: `${(Number(lastMonth.totalAudioText) / 3600).toFixed(2)} hours`, 
          percent: calculatePercentChange(secondLastMonth.totalAudioText, lastMonth.totalAudioText),
          type: "monthly usage",
          text: `Total sold this month: ${(Number(lastSoldMonth.totalAudioText) / 3600).toFixed(2)} hours`, 
        },
        {
          title: "Creative Club",
          body: `${Number(ccSubscriberCount).toLocaleString('en-US')} subscribers`, 
          percent: null,
          type: "monthly subscription",
          text: `Total CC subscribers: ${Number(monthlyChartData.value.ccSubscribersTotal || 0).toLocaleString('en-US')}`, 
        },
        {
          title: "Total",
          body: `${Number(allSubscriberCount).toLocaleString('en-US')} subscribers`, 
          percent: null,
          type: "monthly subscription",
          text: `Total subscribers: ${Number(monthlyChartData.value.allSubscribersTotal || 0).toLocaleString('en-US')}`, 
        },
      ];

  } else {
    return [
      {
        title: "ChatGPT",
        body: `0 words`,
        percent: 0,
        type: "monthly usage",
        text: `Total sold this month: 0 words`,
      },
      {
        title: "DeepGram",
        body: `0 hours`,
        percent: 0,
        type: "monthly usage",
        text: `Total sold this month: 0 hours`,
      },
      {
        title: "Creative Club",
        body: `0 subscribers`,
        percent: null,
        type: "monthly subscription",
        text: `Total CC subscribers: 0`,
      },
      {
        title: "Total",
        body: `0 subscribers`,
        percent: null,
        type: "monthly subscription",
        text: `Total subscribers: 0`,
      },
    ];
  }
});

function calculatePercentChange(oldValue, newValue) {
  const oldVal = parseFloat(oldValue);
  const newVal = parseFloat(newValue);
  if (oldVal === 0) return 0;
  return ((newVal - oldVal) / oldVal * 100).toFixed(1);
}

onMounted(() => {
  const currentDate = new Date();
  const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
  
  store.dispatch("fetchMonthlyChartData", {
    fromYear: fromDate.getFullYear(),
    toYear: currentDate.getFullYear(),
    fromMonth: fromDate.getMonth() + 1,
    toMonth: currentDate.getMonth() + 1,
  });
});
</script>
<style lang="scss" scoped>
.summary {
  flex-shrink: 0;
  border-radius: 6px;
  border: 2px solid #ebedf3;
  background: #fff;
  box-sizing: border-box;
  text-align: left;
  min-width: fit-content;

  &__card {
    padding: 16px 12px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 300px;
    min-height: 150px;
    &--title {
      color: #333;
      font-family: "Montserrat Semi Bold";
      font-size: 16px;
      font-style: normal;
      line-height: 150%;
      span {
        font-size: 14px;
        font-weight: 400 !important;
        color: #333;
      }
    }
    &--body {
      color: #333;
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-family: "Montserrat Semi Bold";
      line-height: 150%;
      display: flex;
      align-items: center;
      gap: 12px;

      span {
        font-family: "Montserrat Medium";
        font-size: 16px;
        font-style: normal;
        line-height: 150%;
      }
    }
    p {
      color: #333;
      font-family: "Montserrat Medium";
      font-size: 14px;
      font-style: normal;
      line-height: 150%;
      margin-bottom: 0 !important;
    }
  }
  .success {
    color: #69c900;
  }
  .danger {
    color: #f00;
  }
}
.summaryTitle {
  color: #2a3f7a;
  font-family: "Montserrat Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
  margin-bottom: 23px;
}
.row {
  --bs-gutter-y: 2rem !important;
}
.summaryFlexRow {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 48px;
  flex-wrap: wrap;
}
</style>
