import { post, get } from "@/utils";
import SaasProductService from '@/services/saasProductService';

const subscriptions = {
  namespaced: true,
  state: () => ({
    subscriptionUpgradeSuccess: null,
    subscriptionUpgradeFailure: null,
    subscriptionDowngradeSuccess: null,
    subscriptionDowngradeFailure: null,
    subscriptionCancelSuccess: null,
    subscriptionCancelFailure: null,
    userData: null,
    paymentMethods: null,
    paymentMethodsError: null,
    paymentMethodsLoading: false,
  }),
  getters: {
    getSubscriptionUpgradeSuccess: (state) => state.subscriptionUpgradeSuccess,
    getSubscriptionUpgradeFailure: (state) => state.subscriptionUpgradeFailure,
    getSubscriptionDowngradeSuccess: (state) => state.subscriptionDowngradeSuccess,
    getSubscriptionDowngradeFailure: (state) => state.subscriptionDowngradeFailure,
    getSubscriptionCancelSuccess: (state) => state.subscriptionCancelSuccess,
    getSubscriptionCancelFailure: (state) => state.subscriptionCancelFailure,
    getUserSubscriptionData: (state) => state.userData,
    getPaymentMethods: (state) => state.paymentMethods,
    getPaymentMethodsError: (state) => state.paymentMethodsError,
    getPaymentMethodsLoading: (state) => state.paymentMethodsLoading,
  },
  mutations: {
    upgradeSubscription: (state, data) => {
      if (data.failure) {
        state.subscriptionUpgradeFailure = data.message;
        state.subscriptionUpgradeSuccess = null;
      } else {
        state.subscriptionUpgradeSuccess = data.message;
        state.subscriptionUpgradeFailure = null;
        state.userData = data.userData;
      }
    },
    downgradeSubscription: (state, data) => {
      if (data.failure) {
        state.subscriptionDowngradeFailure = data.message;
        state.subscriptionDowngradeSuccess = null;
      } else {
        state.subscriptionDowngradeSuccess = data.message;
        state.subscriptionDowngradeFailure = null;
        state.userData = data.userData;
      }
    },
    cancelSubscription: (state, data) => {
      state.subscriptionCancelSuccess = data.success ? data.message : null;
      state.subscriptionCancelFailure = data.failure ? data.message : null;
      // Update userData if necessary
    },
    hideToastSubscriptionAlert: (state) => {
      state.subscriptionUpgradeFailure = null;
      state.subscriptionUpgradeSuccess = null;
      state.subscriptionDowngradeFailure = null;
      state.subscriptionDowngradeSuccess = null;
      state.subscriptionCancelFailure = null;
      state.subscriptionCancelSuccess = null;
    },
    setPaymentMethodsLoading: (state, isLoading) => {
      state.paymentMethodsLoading = isLoading;
    },
    setPaymentMethods: (state, paymentMethods) => {
      state.paymentMethods = paymentMethods;
    },
    setPaymentMethodsError: (state, error) => {
      state.paymentMethodsError = error;
    },
    
  },
  actions: {
    upgradeSubscription({ commit }) {
      let upgradeData = {};
      post("/subscription/upgrade")
        .then((data) => {
          if (data.status == 200) {
            upgradeData.message = "Upgraded";
            upgradeData.failure = false;
            upgradeData.userData = data.response.data.userData;
          } else {
            upgradeData.message = data.response.data.message;
            upgradeData.failure = true;
          }
        })
        .catch(() => {
          upgradeData.message = "Failure";
          upgradeData.failure = true;
        })
        .finally(() => {
          commit("upgradeSubscription", upgradeData);
        });
    },
    downgradeSubscription({ commit }) {
      let downgradeData = {};
      post("/subscription/downgrade")
        .then((data) => {
          if (data.status == 200) {
            downgradeData.message = "Downgraded";
            downgradeData.failure = false;
            downgradeData.userData = data.response.data.userData;
          } else {
            downgradeData.message = data.response.data.message;
            downgradeData.failure = true;
          }
        })
        .catch(() => {
          downgradeData.message = "Failure";
          downgradeData.failure = true;
        })
        .finally(() => {
          commit("downgradeSubscription", downgradeData);
        });
    },
    cancelSubscription({ commit }) {
      return post('/subscription/cancel')
        .then((response) => {
          const data = response.data;
          const cancelData = {
            success: data.success === 1,
            message: data.message,
            failure: data.success === 0
          };
          commit('cancelSubscription', cancelData);
          return cancelData;
        })
        .catch((error) => {
          const cancelData = {
            success: false,
            message: error.response?.data?.message || 'Failed to cancel subscription',
            error: error.response?.data || error.message,
            failure: true
          };
          commit('cancelSubscription', cancelData);
          return cancelData;
        });
    },
    hideToastSubscriptionAlert({ commit }) {
      commit("hideToastSubscriptionAlert");
    },
    async upgradeSubscriptionToCreativeClub({ commit, dispatch }, { userId, ccProductId }) {
      commit('setPaymentMethodsLoading', true);
      try {
        // Check if user is already on Creative Club
        const currentUserProduct = await SaasProductService.getUserProduct(userId);
        if (currentUserProduct && currentUserProduct.productName && currentUserProduct.productName.indexOf("Creative") !== -1) {
          throw new Error("User is already on Creative Club Subscription.");
        }
  
        // Perform the upgrade
        const response = await post(`/subscription/${userId}/upgrade-creative-club`, {
          ccProductId
        });
  
        // Check if the response indicates success
        if (response.data && !response.data.error) {
          commit('upgradeSubscription', {
            message: "Upgraded to Creative Club",
            failure: false,
            userData: response.data.userData || response.data
          });
          await dispatch('users/updateUserAsAdmin', userId, { root: true });
          return { success: true, message: "Upgrade successful" };
        } else {
          const errorMessage = response.data.message || response.data.error || "User could not be upgraded to Creative Club.";
          throw new Error(errorMessage);
        }
      } catch (error) {
        commit('upgradeSubscription', {
          message: error.message || "An error occurred.",
          failure: true
        });
        throw error;
      } finally {
        commit('setPaymentMethodsLoading', false);
      }
    },
    async downgradeSubscriptionFromCreativeClub({ commit, dispatch}, userId) {
      commit('setPaymentMethodsLoading', true);
      try {
        const response = await post(`/subscription/${userId}/downgrade-creative-club`);
          if(response.data && !response.data.error) {
            commit("downgradeSubscription", {
              message: "Downgraded from Creative Club",
              failure: false,
              userData: response.data.userData || response.data
            });
            await dispatch('users/updateUserAsAdmin', userId, { root: true });
          return { success: true, message: "Downgrade successful" };
          } else {
            const errorMessage = response.data.message || response.data.error || "User could not be downgraded from Creative Club.";
            throw new Error(errorMessage);
          }
      } catch (error) {
        commit('downgradeSubscription', {
          message: error.message || "An error occurred.",
          failure: true
        });
        return { success: false, message: error.message || "An error occurred." };
      } finally {
        commit('setPaymentMethodsLoading', false);
      }
    },
    async upgradeToCreativeClub({ commit, dispatch }, billingPeriod) {
      commit('setPaymentMethodsLoading', true);
      try {
        // Check if user is already on Creative Club
        const currentUserProduct = await SaasProductService.getUserProduct();
        if (currentUserProduct && currentUserProduct.productName && currentUserProduct.productName.indexOf("Creative") !== -1) {
          throw new Error("User is already on Creative Club Subscription.");
        }
    
        const ccProducts = await SaasProductService.getProductsCC(billingPeriod);
    
        if (ccProducts.length === 0) {
          throw new Error("Suitable upgrade product could not be found.");
        }
    
        const product = ccProducts[0];
    
        // Perform the upgrade
        const response = await post("/subscription/upgrade-creative-club", {
          ccProductId: product.id,
        });
    
        // Check if the response indicates success, even if there's no explicit 'success' property
        if (response.data && !response.data.error) {
          commit('upgradeSubscription', {
            message: "Upgraded to Creative Club",
            failure: false,
            userData: response.data.userData || response.data
          });
          dispatch('users/updateCurrentUser', null, { root: true });
          return { success: true, message: "Upgrade successful" };
        } else {
          // If there's an error message in the response, use it; otherwise, use a default message
          const errorMessage = response.data.message || response.data.error || "User could not be upgraded to Creative Club.";
          throw new Error(errorMessage);
        }
      } catch (error) {
        commit('upgradeSubscription', {
          message: error.message || "An error occurred.",
          failure: true
        });
        return { success: false, message: error.message || "An error occurred." };
      } finally {
        commit('setPaymentMethodsLoading', false);
      }
    },


    async fetchPaymentMethods({ commit }) {
      commit('setPaymentMethodsLoading', true);
      commit('setPaymentMethodsError', null);
    
      try {
        const response = await get('/subscription/payment-method');
        
        if (response && response.status === 200 && response.data) {
          const paymentMethod = {
            paymentMethodType: response.data.type,
            paymentMethodName: response.data?.method?.name || "n/a",
            paymentType: response.data.type === 'Card' ? response.data?.method?.ccType : 'PayPal',
            expireMonth: response.data.type === 'Card' ? response.data?.method?.ccMonth : null,
            expireYear: response.data.type === 'Card' ? response.data?.method?.ccYear : null,
            cardLastDigits: response.data.type === 'Card' ? response.data?.method?.ccLastDigits : null,
            paymentEmail: response.data.type === 'PayPal' ? response.data?.method?.email : null,
          };
          commit('setPaymentMethods', paymentMethod);
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        console.error('Error in fetchPaymentMethods:', error);
        commit('setPaymentMethods', null);
        commit('setPaymentMethodsError', error.message || 'An error occurred while fetching payment methods.');
      } finally {
        commit('setPaymentMethodsLoading', false);
      }
    },
    
  },
};
export default subscriptions;