import { patch, post, get, del } from "@/utils";

const users = {
  state: () => ({
    currentUser: {},
    currentUserLoading: false,
    subUserInviteSuccess: null,
    subUserInviteMessage: null,
    allUsers: [],
    allUsersLoading: false,
    allUsersErrorMessage: null,
    uploadAvatarMessage: {
      success: null,
      failure: null,
    },
    updateAvatarMessage: {
      success: null,
      failure: null,
    },
    deleteAvatarMessage: {
      success: null,
      failure: null,
    },
    updateCurrentUserNameMessage: {
      success: null,
      failure: null,
    },
    deactivateUserMessage: {
      success: null,
      failure: null,
    },
    changeUserFullNameMessage: {
      success: null,
      failure: null,
    },
    singleDataUserForAdmin: null,
    singleDataUserForAdminErrorMessage: null,
    singleDataUserForAdminLoading: false,
  }),
  getters: {
    getCurrentUser: (state) => state.currentUser,
    getCurrentUserLoading: (state) => state.currentUserLoading,
    getSubUserInviteSuccess: (state) => state.subUserInviteSuccess,
    getSubUserInviteMessage: (state) => state.subUserInviteMessage,
    getAllUsers: (state) => state.allUsers,
    getAllUsersLoading: (state) => state.allUsersLoading,
    getChangeUserFullNameMessage: (state) => state.changeUserFullNameMessage,
    getDeactivateUserMessage: (state) => state.deactivateUserMessage,
    getUploadAvatarMessage: (state) => state.uploadAvatarMessage,
    getUpdateAvatarMessage: (state) => state.updateAvatarMessage,
    getDeleteAvatarMessage: (state) => state.deleteAvatarMessage,
    getUpdateCurrentUserNameMessage: (state) => state.updateCurrentUserNameMessage,
    getSingleDataUserForAdmin: state => state.singleDataUserForAdmin,
    getSingleDataUserErrorMessageForAdmin: state => state.singleDataUserForAdminErrorMessage,
    getSingleDataUserLoadingForAdmin : state => state.singleDataUserForAdminLoading,
  },
  mutations: {
    updateCurrentUser: (state, userData) => {
      if (userData) {
        state.currentUser = userData;
      }
    },
    currentUserLoading: (state, bool) => {
      state.currentUserLoading = bool;
    },
    allUsers: (state, data) => {
      if (data.errorMessage) {
        state.allUsersErrorMessage = data.errorMessage;
      } else if (data.index == null) {
        state.allUsers = data;
      } else {
        state.allUsers.items[data.index].parentData = data;
      }
    },
    allUsersLoading: (state, payload) => {
      state.allUsersLoading = payload;
    },
    deactivateUser: (state, data) => {
      if (!data.failure) {
        state.deactivateUserMessage.success = data.message;
        state.deactivateUserMessage.failure = null;
      } else {
        state.deactivateUserMessage.success = null;
        state.deactivateUserMessage.failure = data.message;
      }
    },

    resetDeactivateUserMessage: (state) => {
      state.deactivateUserMessage.success = null;
      state.deactivateUserMessage.failure = null;
    },
    changeUserFullNameWithAdmin: (state, data) => {
      if (data.failure) {
        state.changeUserFullNameMessage.success = null;
        state.changeUserFullNameMessage.failure = data.message;
      } else {
        state.changeUserFullNameMessage.success = data.message;
        state.changeUserFullNameMessage.failure = null;
      }
    },
    resetChangeUserFullNameMessage: (state) => {
      state.changeUserFullNameMessage.success = null;
      state.changeUserFullNameMessage.failure = null;
    },
    subUserInvite: (state, data) => {
      if (data) {
        state.subUserInviteSuccess = false;
        state.subUserInviteMessage = typeof data == "string" ? data : data[0];
      } else {
        state.subUserInviteSuccess = true;
        state.subUserInviteMessage = "Your invite has been successfully sent !";
      }
    },
    hideToastAlert: (state) => {
      state.subUserInviteSuccess = null;
    },

    uploadAvatar: (state, success) => {
      if (success) {
        state.uploadAvatarMessage.success = true;
        state.uploadAvatarMessage.failure = false;
      } else {
        state.uploadAvatarMessage.success = false;
        state.uploadAvatarMessage.failure = true;
      }
    },
    updateAvatar: (state, data) => {
      if (data.success) {
        state.updateAvatarMessage.success = true;
        state.updateAvatarMessage.failure = null;
      } else {
        state.updateAvatarMessage.success = null;
        state.updateAvatarMessage.failure = data.errorMessage;
      }
    },
    deleteAvatar: (state, success) => {
      if (success) {
        state.deleteAvatarMessage.success = true;
        state.deleteAvatarMessage.failure = null;
      } else {
        state.deleteAvatarMessage.success = null;
        state.deleteAvatarMessage.failure = true;
      }
    },
    resetAvatarMessage: (state) => {
      state.updateAvatarMessage.success = null;
      state.updateAvatarMessage.failure = null;
      state.uploadAvatarMessage.failure = null;
      state.uploadAvatarMessage.success = null;
      state.deleteAvatarMessage.success = null;
      state.deleteAvatarMessage.failure = null;
    },
    updateCurrentUserName: (state, data) => {
      if (data) {
        state.currentUser = {
          ...state.currentUser,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email
        };
        state.updateCurrentUserNameMessage.success = true;
        state.updateCurrentUserNameMessage.failure = null;
      } else {
        state.updateCurrentUserNameMessage.failure = true;
        state.updateCurrentUserNameMessage.success = null;
      }
    },
    resetCurrentUserNameMessage: (state) => {
      state.updateCurrentUserNameMessage.success = null;
      state.updateCurrentUserNameMessage.failure = null;
    },
    editSingleUserForAdmin: (state, data) => {
      state.singleDataUserForAdmin = data;
    },
    editSingleUserForAdminErrorMessage: (state, message) => {
      state.singleDataUserForAdminErrorMessage = message
    },
    editSingleUserForAdminLoading: (state, bool) => {
      state.singleDataUserForAdminLoading = bool
    }
  },
  actions: {
    updateCurrentUser({ commit }) {
      commit("currentUserLoading", true);
      get("/users/me")
        .then((response) => {
          if (response.status == 200) {
            commit("updateCurrentUser", response.data);
          }
        })
        .catch(() => {
          // commit("updateCurrentUser", null);
        })
        .finally(() => {
          commit("currentUserLoading", false);
        });
    },
    async updateUserAsAdmin({ commit }, userId) {
      try {
        const response = await get(`/users/${userId}`);
        if (response.data) {
          commit('updateCurrentUser', response.data);
          return response.data;
        }
      } catch (error) {
        console.error('Error updating user as admin:', error);
        throw error;
      }
    },
    subUserInvite({ commit }, payload) {
      post("/users/invite/", payload)
        .then((data) => {
          if (data.status == 200) {
            commit("subUserInvite", false);
          } else {
            commit("subUserInvite", data.response.data.message);
          }
        })
        .catch(() => {
          commit("subUserInvite", "Error message");
        });
    },
    hideToastAlert({ commit }) {
      commit("hideToastAlert");
    },
    allUsers({ commit }, payload) {
      let endPoint = "/users";
      if (!payload?.page) {
        endPoint += "?page=1";
      } else {
        endPoint += `?page=${payload.page}`;
      }
      if (!payload?.limit) {
        endPoint += "&limit=10";
      } else {
        endPoint += `&limit=${payload.limit}`;
      }
      // if (payload?.role) {
      //   endPoint += `&role=${payload.role}`;
      // }
      commit("allUsersLoading", true);

      get(endPoint)
        .then(async (response) => {
          if (response.status == 200) {
            let allUsersData = await response.data;
            allUsersData.index = null;
            commit("allUsers", allUsersData);
            

            allUsersData.items.map((item, index) => {
              if (item.parentId) {
                get(`/users/${item.parentId}`).then(async (response) => {
                  if (response.status == 200) {
                    let data = response.data;
                    data.index = index;
                    commit("allUsers", response.data);
                    commit("allUsersLoading", false); 
                  } else {
                    let data = {};
                    data.index = index;
                    commit("allUsers", data);
                  }
                });
              }
            });
          } else {
            let dataError = {};
            dataError.errorMessage = response.response.data.message;
            commit("allUsers", dataError);
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          commit("allUsersLoading", false);
        });
    },
    deactivateUser({ commit }, id) {
      let delData = {};
      del(`/licenses/${id}/invalidate`)
        .then((data) => {
          if (data.status == 200) {
            delData.failure = false;
            delData.message = "You have successfully deactivated the user!";
          } else {
            delData.failure = true;
            delData.message = "You have failed to deactivate the user!";
          }
        })
        .catch(() => {
          delData.failure = true;
          delData.message = "You have failed to deactivate the user!";
        })
        .finally(() => {
          commit("deactivateUser", delData);
        });
    },
    resetDeactivateUserMessage({ commit }) {
      commit("resetDeactivateUserMessage");
    },
    changeUserFullNameWithAdmin({ commit }, { firstName, lastName, id, email, status }) {
      return new Promise((resolve, reject) => {
        patch(`/users/${id}`, { firstName, lastName, email, status })
          .then((response) => {
            console.log("Payload:", { firstName, lastName, email, status });
            if (response.status === 200) {
              commit("changeUserFullNameWithAdmin", {
                failure: false,
                message: "You have successfully edited sub-user information!"
              });
              resolve(response.data);
            } else {
              throw new Error("Unexpected response status");
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 422 && error.response.data.message === "Provided email is already taken") {
              reject({ emailError: "Email is already taken" });
            } else {
              commit("changeUserFullNameWithAdmin", {
                failure: true,
                message: "You have failed to edit user information!"
              });
              reject(error);
            }
          });
      });
    },
    resetChangeUserFullNameMessage({ commit }) {
        commit("resetChangeUserFullNameMessage");
    },
    uploadAvatar({ commit }, payload) {
      post("/users/avatar", payload, {
        "Content-Type": "multipart/form-data",
      })
        .then((data) => {
          if (data.status == "201") {
            commit("uploadAvatar", true);
          } else {
            commit("uploadAvatar", false);
          }
        })
        .catch(() => {
          commit("uploadAvatar", false);
        });
    },
    updateAvatar({ commit }, payload) {
      let updateAvatarData = {};
      patch("/users/avatar/set", payload)
        .then((data) => {
          if (data.status == 200) {
            updateAvatarData.success = true;
            updateAvatarData.errorMessage = null;
          } else {
            updateAvatarData.success = false;
            updateAvatarData.errorMessage = "Avatar has not been updated";
          }
        })
        .catch(() => {
          updateAvatarData.success = false;
          updateAvatarData.errorMessage = "Avatar has not been updated";
        })
        .finally(() => {
          commit("updateAvatar", updateAvatarData);
        });
    },
    deleteAvatar({ commit }, payload) {
      del(`/users/avatar/${payload}/delete`)
        .then((data) => {
          if (data.status == 200) {
            commit("deleteAvatar", true);
          } else {
            commit("deleteAvatar", false);
          }
        })
        .catch(() => {
          commit("deleteAvatar", false);
        });
    },
    resetAvatarMessage({ commit }) {
      commit("resetAvatarMessage");
    },
    updateCurrentUserName({ commit }, { firstName, lastName, email }) {
      console.log('updateCurrentUserName action called with:', { firstName, lastName, email });
      
      return patch("/users/me", { firstName, lastName, email })
        .then((response) => {
          console.log('API response:', response);
          
          if (response.status === 200) {
            console.log('API response data:', response.data);
            commit("updateCurrentUserName", {
              firstName,
              lastName,
              email,
              success: true,
            });
            return { success: true, data: response.data };
          } else {
            console.error('API error:', response.statusText);
            commit("updateCurrentUserName", { success: false });
            return { success: false, error: response.statusText };
          }
        })
        .catch((error) => {
          console.error("Error updating user:", error);
          commit("updateCurrentUserName", { success: false });
          return { success: false, error: error.message };
        });
    },
    resetCurrentUserNameMessage({ commit }) {
      commit("resetCurrentUserNameMessage");
    },
    allUsersForAdmin({ commit }, payload) {
      let endPoint = "/users/admin";
      // let params = new URLSearchParams();
      if (!payload?.page) {
        endPoint += "?page=1";
      } else {
        endPoint += `?page=${payload.page}`;
      }
      if (!payload?.limit) {
        endPoint += "&limit=10";
      } else {
        endPoint += `&limit=${payload.limit}`;
      }
      if (payload?.role?.title) {
        endPoint += `&role=${payload.role.title}`;
        console.log(payload.role, typeof(payload?.role), "role")
      }
      if (payload?.subscriptionType) {
        endPoint += `&subscriptionType=${payload.subscriptionType}`;
      }
      // if (payload?.subscriptionType) {
      //   if (Array.isArray(payload.subscriptionType)) {
      //     // Handle multiple subscription types
      //     payload.subscriptionType.forEach(type => {
      //       params.append('subscriptionType[]', encodeURIComponent(type));
      //     });
      //   } else {
      //     // Handle single subscription type
      //     params.append('subscriptionType', encodeURIComponent(payload.subscriptionType));
      //   }
      // }
      

      if (payload?.fromDate) {
        endPoint += `&fromDate=${payload.fromDate}`;
      }
      if (payload?.toDate) {
        endPoint += `&toDate=${payload.toDate}`;
      }
      function encodeSearchData(searchData) {
        if (!searchData) return searchData;
        return encodeURIComponent(searchData);
      }
      if (payload?.searchData) {
        const encodedSearchData = encodeSearchData(payload.searchData.toLowerCase());
        endPoint += `&search=${encodedSearchData}`;
      }

      get(endPoint)
        .then(async (response) => {
          if (response.status == 200) {
            let allUsersData = await response.data;
            allUsersData.index = null;
            commit("allUsers", allUsersData);

            allUsersData.items.map((item, index) => {
              if (item.parentId) {
                get(`/users/${item.parentId}`).then(async (response) => {
                  if (response.status == 200) {
                    let data = response.data;
                    data.index = index;
                    commit("allUsers", response.data);
                    commit("allUsersLoading", false);
                  } else {
                    let data = {};
                    data.index = index;
                    commit("allUsers", data);
                  }
                });
              }
            });
          } else {
            let dataError = {};
            dataError.errorMessage = response.response.data.message;
            commit("allUsers", dataError);
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          commit("allUsersLoading", false);
        });
    },
    singleUserForAdmin({ commit }, payloadId) {
      get(`/users/${payloadId}`).then((data) => {
        commit("editSingleUserForAdminLoading", true)
        if (data.status == 200) {
          commit("editSingleUserForAdmin", data.data)
          commit("editSingleUserForAdminErrorMessage", null)
        } else {
          commit("editSingleUserForAdmin", null)
          commit("editSingleUserForAdminErrorMessage", "User Not Found")
        }
      }).catch(() => {
        commit("editSingleUserForAdmin", null)
        commit("editSingleUserForAdminErrorMessage", "User Not Found")
      }).finally(() => {
        commit("editSingleUserForAdminLoading", false)
      })
    }
  },
};

export default users;
